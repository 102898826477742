/** @jsx jsx */
import { SvgIcon } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useContext, useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Helmet from "react-helmet";
import { Box, Container, Flex, jsx, Themed } from "theme-ui";
import Accordion from "../components/Accordion";
import BreadCrumbs from "../components/BreadCrumbs";
import ContentfulComponent from "../components/ContentfulComponent";
import { MoveUp } from "../components/Icon";
import Layout from "../components/Layout";
import Link from "../components/Link";
import Logo from "../components/Logo";
import RichText2 from "../components/RichText2";
import SEO from "../components/SEO";
import { LocalizationContext } from "../context/LocalizationContext";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { useSitePages } from "../hooks/useSitePages";
import { generatePDF } from "../utils/api";
import { getUrlFromSlug } from "../utils/utils";
import colors from "../gatsby-plugin-theme-ui/colors";

const InsuranceBrochurePageTemplate = ({ data, pageContext }) => {
  const nodes = useSitePages();
  const [isClient, setClient] = useState(false);
  const key = isClient ? "client" : "server";
  const [PDF, setPDF] = useState(false);
  const [generatedPdfUrl, setGeneratedPdfUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [print, setPrint] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  const useStyles = makeStyles({
    backToTopButton: {
      backgroundColor: "#f6f6f6",
      pointerEvents: "auto",
      '&:hover': {
        background: colors.primary + "!important",
      },
      '&:focus': {
        background: colors.primary + "!important",
      },
    },
  });

  const classes = useStyles();

  const handleClick = async () => {
    setPDF(true);
    setLoading(true);
    const pdfUrl = await generatePDF(slug, title);
    setGeneratedPdfUrl(pdfUrl.url);
    setLoading(false);
    download(null, pdfUrl.url);
  };

  const [showCalc, setShowCalc] = useState(true);

  useEffect(() => {
    if (isClient) {
      const queryString = window.location.search;
      if (queryString.includes("nocalc")) {
        setShowCalc(false);
      }
    }
  }, [isClient]);

  useEffect(() => {
    const queryString = window.location.search;
    if (queryString.includes("print")) {
      setPrint(true);
    }
  }, []);

  const {
    pageType,
    title,
    productName,
    navigationTitle,
    validityStartDate,
    siteSearchKeywords,
    metaDescription,
    productDescription,
    calculatorComponent,
    accordionTitle,
    productAccordions,
    slug,
    titleImage,
    updatedAt,
    hidden,
    noIndex,
    pdfUrlFi,
    pdfUrlSv,
    id,
  } = data.contentfulInsuranceBrochure;
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    author,
    social,
    organization,
  } = useSiteMetadata();

  const previous = pageContext.prev;
  const next = pageContext.next;
  const { basePath, node_locale: locale, language } = pageContext;

  const metadataTitle = title;

  const metadataDescription =
    metaDescription &&
    metaDescription.internal &&
    metaDescription.internal.content
      ? metaDescription.internal.content
      : defaultDescription;

  const metadataKeywords = siteSearchKeywords ? siteSearchKeywords : "";

  let metaImage = defaultImage;

  const validDate = new Date(+validityStartDate);
  const momentObj = dayjs(validDate);
  const metadataDateValid = momentObj.format("YYYY-MM-DD hh:mm:ssZ");
  const date = new Date(+validDate);

  const { translate } = useContext(LocalizationContext);

  const startDate = `${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()}`;

  const download = (event, downloadUrl) => {
    let a = document.createElement("a");
    a.style = "display: none";
    a.href = downloadUrl ? downloadUrl : generatedPdfUrl;
    a.download = "";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const PdfRenderer = () => (
    <div>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            marginTop: 35,
          }}
        >
          <Themed.h3>{translate("terms.loadingPDF", null, language)}</Themed.h3>
        </Box>
      ) : (
        <Box
          id="download-button"
          onClick={download}
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 35,
            height: 50,
            color: "#00850d",
          }}
        >
          <Themed.h3 style={{ display: "inline", cursor: "pointer" }}>
            {translate("terms.downloadPDF", null, language)}
          </Themed.h3>
          <SvgIcon
            fontSize={"large"}
            component={"svg"}
            viewBox={"0 0 45 45"}
            style={{ display: "inline", marginLeft: 10 }}
          >
            <svg>
              <path d="M19.58594,4,9,14.58594V33h2V16H21V6H37V42H10v2H39V4ZM12.41406,14,19,7.41406V14Z" />
              <polygon points="15 33 15 38 33 38 33 33 31 33 31 36 17 36 17 33 15 33" />
              <polygon points="25 33 25 22.414 27.293 24.707 28.707 23.293 24 18.586 19.293 23.293 20.707 24.707 23 22.414 23 33 25 33" />
            </svg>
          </SvgIcon>
        </Box>
      )}
    </div>
  );

  const pdfLinkToShow = () => {
    let staticPdfUrl;
    if (locale === "fi-FI") {
      staticPdfUrl = pdfUrlFi;
    } else if (locale === "sv-SE") {
      staticPdfUrl = pdfUrlSv;
    }
    if (!staticPdfUrl) {
      if (isClient && PDF) {
        return <PdfRenderer key={key} />;
      } else {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: 35,
              height: 50,
            }}
          >
            <Themed.h3
              onClick={handleClick}
              style={{
                color: "#00850d",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              {translate("terms.createPDF", null, language)}
            </Themed.h3>
          </Box>
        );
      }
    } else {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            marginTop: 35,
            color: "#00850d",
            textDecoration: "none",
          }}
        >
          <Link
            target={staticPdfUrl}
            sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            {translate("terms.downloadPDF", null, language)}
          </Link>
        </Box>
      );
    }
  };
  if (!print) {
    return (
      <Layout pageContext={pageContext} pageType={pageType}>
        <SEO
          lang={pageContext.language}
          title={metadataTitle}
          description={metadataDescription}
          keywords={metadataKeywords}
          image={metaImage}
          paths={pageContext.paths}
          hidden={hidden}
          noIndex={noIndex}
        />
        <Container id={"top"}>
          <BreadCrumbs
            slug={slug}
            navigationTitle={navigationTitle}
            language={language}
          />
        </Container>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 4,
          }}
        >
          <Box
            variant="wide"
            id="content"
            sx={{
              mt: "20px",
            }}
          >
            {titleImage ? (
              <GatsbyImage
                image={titleImage.gatsbyImageData}
                sx={{
                  width: "100%",
                  height: "auto",
                }}
                alt={titleImage.description ? titleImage.description : ""}
              />
            ) : (
              ""
            )}
          </Box>
          <Box
            sx={{
              position: "sticky",
              height: 0,
              top: "90vh",
              display: ["none", null, null, "inline-flex"],
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              mb: 3,
              zIndex: 97,
              pointerEvents: "none",
              paddingRight: 4,
            }}
          >
            <Themed.p
              sx={{
                marginRight: "10px",
                width: "65px",
                textAlign: "right",
              }}
            >
              {translate("brochure.toTop", null, language)}
            </Themed.p>
            <AnchorLink
              href={"#top"}
              offset="100"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <IconButton
                aria-controls="navigation"
                aria-label={"Takaisin ylös"}
                className={classes.backToTopButton}
              >
                <MoveUp />
              </IconButton>
            </AnchorLink>
          </Box>
          <Flex
            sx={{
              my: "20px",
            }}
          >
            <Flex
              sx={{
                flexGrow: [1, 1, 1, 0],
                flexShrink: 1,
                flexBasis: ["0%", "0%", "0%", "84%"],
                flexDirection: ["column", "column", "row", "row"],
                gap: 3,
              }}
            >
              <Box
                variant="wide"
                id="content"
                sx={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: ["0%", "0%", "50%", "50%"],
                }}
              >
                <Themed.h1 sx={{ color: "#008a28" }}>{title}</Themed.h1>
                <Themed.h2>{productName}</Themed.h2>
                <Themed.p
                  sx={{
                    mb: [3, 5],
                    mt: -3,
                  }}
                >
                  {translate("terms.valid", null, language)} {startDate}{" "}
                  {translate("terms.starting", null, language)}
                </Themed.p>
                {productDescription && (
                  <RichText2
                    content={productDescription}
                    locale={locale}
                    language={language}
                  />
                )}
              </Box>
              {showCalc && calculatorComponent ? (
                <Box
                  sx={{
                    zIndex: 98,
                    padding: 0,
                    marginLeft: [-2, 0, 0, 0],
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: "auto",
                  }}
                >
                  <ContentfulComponent
                    template={calculatorComponent.template}
                    data={calculatorComponent.data}
                    locale={locale}
                    language={language}
                  />
                </Box>
              ) : (
                ""
              )}
            </Flex>
          </Flex>
          <Flex>
            <Box
              variant="wide"
              sx={{
                flexGrow: [1, 1, 1, 0],
                flexShrink: 1,
                flexBasis: ["0%", "0%", "0%", "84%"],
              }}
            >
              <Themed.h3>{accordionTitle}</Themed.h3>
              {productAccordions.map((accordion, index) => {
                return (
                  <Accordion
                    key={`product-accordion-${index}`}
                    title={accordion.title}
                    content={accordion.content}
                    index={index}
                    locale={locale}
                    language={language}
                  />
                );
              })}
              {pdfLinkToShow()}
            </Box>
          </Flex>
        </Container>
      </Layout>
    );
  } else {
    return (
      <Container variant="wide" id="content">
        <div aria-label="logo">
          <Logo />
        </div>
        {titleImage ? (
          <GatsbyImage
            image={titleImage.gatsbyImageData}
            sx={{
              width: "100%",
              height: "auto",
              marginTop: 40,
            }}
            alt={titleImage.description ? titleImage.description : ""}
          />
        ) : (
          ""
        )}
        <Themed.h1 sx={{ color: "#008a28" }}>{title}</Themed.h1>
        <Themed.h2>{productName}</Themed.h2>
        <Themed.p
          sx={{
            mb: [3, 5],
            mt: -3,
          }}
        >
          {translate("terms.valid", null, language)} {startDate}{" "}
          {translate("terms.starting", null, language)}
        </Themed.p>
        {productDescription && (
          <RichText2
            content={productDescription}
            locale={locale}
            language={language}
          />
        )}
        <Themed.h3>{accordionTitle}</Themed.h3>
        {productAccordions.map((accordion, index) => {
          return (
            <Accordion
              key={`product-accordion-${index}`}
              title={accordion.title}
              content={accordion.content}
              index={index}
              locale={locale}
              language={language}
            />
          );
        })}
      </Container>
    );
  }
};

export const query = graphql`
  query ($id: String!, $aspectRatio: Float) {
    contentfulInsuranceBrochure(id: { eq: $id }) {
      id
      title
      productName
      navigationTitle
      siteSearchKeywords
      accordionTitle
      hidden
      noIndex
      pdfUrlFi
      pdfUrlSv
      titleImage {
        gatsbyImageData(quality: 85, width: 1080, aspectRatio: $aspectRatio)
        description
      }
      calculatorComponent {
        template
        title
        data {
          link
        }
      }
      productAccordions {
        id
        contentful_id
        __typename
        anchorLink
        title
        node_locale
        content {
          raw
          references {
            ... on ContentfulAccordion {
              id
              contentful_id
              __typename
              anchorLink
              title
              node_locale
              content {
                raw
                references {
                  ... on ContentfulAccordion {
                    id
                    contentful_id
                    __typename
                    anchorLink
                    title
                    content {
                      raw
                    }
                  }
                }
              }
            }
            ...fragmentContentfulLiftUpBox
          }
        }
      }
      metaDescription {
        internal {
          content
        }
      }
      validityStartDate(formatString: "x")
      productDescription {
        raw
        references {
          ...fragmentContentfulInlineImageFlex
        }
      }
      slug
      updatedAt
    }
  }
`;
export default InsuranceBrochurePageTemplate;
